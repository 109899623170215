import React, { useState, useEffect, useContext } from 'react'
import '../Style/LoginForm.css'
import { useCookies } from 'react-cookie'
import { useNavigate, useLocation } from 'react-router-dom'
import { TextField, Button, Box, Container, Typography } from '@mui/material'
import { create } from '@mui/material/styles/createTransitions'
import Base from '../Components/Base'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

const ModificationForm = () => {
  const [cookies] = useCookies(['token'])
  const location = useLocation()
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const { book } = location.state || { book: null }

  useEffect(() => {
    if (!book) {
      // Rediriger vers la page de login si l'état 'book' n'est pas défini
      navigate('/', { replace: true })
    }
  }, [book, navigate])
  const apiUrl = process.env.REACT_APP_API_URL

  const [formValues, setFormValues] = useState({
    id: book ? book.id : '',
    title: book ? book.title : '',
    author: book ? book.author : null,
    summary: book ? book.summary : null,
    publisher: book ? book.publisher : null,
    isbn_10: book ? book.isbn_10 : null,
    isbn_13: book ? book.isbn_13 : null,
    pages: book ? book.pages : null,
    publish_date: book ? book.publish_date : null,
    started_date: book ? book.started_date : null,
    ended_date: book ? book.ended_date : null,
    note_5: book ? book.note_5 : null,
    personnal_note: book ? book.personnal_note : null,
    image_url: book ? book.image_url : null,
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  function edit_book(event) {
    event.preventDefault()
    const xhr = new XMLHttpRequest()
    xhr.open('PATCH', `${apiUrl}/books/edit/${formValues.id}`)
    xhr.setRequestHeader('Authorization', 'Bearer ' + cookies.token)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.onload = function () {
      if (xhr.status === 200) {
        navigate('/')
      } else {
        setError(true)
      }
    }
    xhr.send(JSON.stringify(formValues))
  }

  return (
    <Base>
      <Container maxWidth="sm">
        <Typography variant="h4" component="div" align="center">
          Modifier un livre
        </Typography>
        <Box
          component="form"
          onSubmit={edit_book}
          noValidate
          autoComplete="off"
          sx={{ mt: 4 }}
        >
          <TextField
            fullWidth
            label="Titre"
            variant="filled"
            margin="normal"
            name="title"
            onChange={handleChange}
            defaultValue={formValues.title}
            required
          />
          <TextField
            fullWidth
            label="Auteur"
            variant="filled"
            margin="normal"
            name="author"
            onChange={handleChange}
            defaultValue={formValues.author}
          />
          <TextField
            fullWidth
            label="Édition"
            variant="filled"
            margin="normal"
            name="publisher"
            onChange={handleChange}
            defaultValue={formValues.publisher}
          />
          <DatePicker
            label="Date de début"
            defaultValue={
              formValues.publish_date ? dayjs(formValues.publish_date) : null
            }
            onChange={(newValue) =>
              newValue !== null
                ? (formValues.publish_date =
                    dayjs(newValue).format('YYYY-MM-DD'))
                : delete formValues.publish_date
            }
            name="publish_date"
            slotProps={{
              textField: {
                variant: 'filled',
                fullWidth: true,
                sx: { mt: 2, mb: 1 },
              },
              field: { clearable: true },
            }}
          />
          <TextField
            fullWidth
            label="Pages"
            variant="filled"
            margin="normal"
            name="pages"
            onChange={handleChange}
            defaultValue={formValues.pages}
            type="number"
          />
          <TextField
            fullWidth
            label="Résumé"
            variant="filled"
            margin="normal"
            name="summary"
            onChange={handleChange}
            defaultValue={formValues.summary}
            multiline
            rows={4}
          />
          <TextField
            fullWidth
            label="Note"
            variant="filled"
            margin="normal"
            name="note_5"
            onChange={handleChange}
            defaultValue={formValues.note_5}
            type="number"
          />
          <TextField
            fullWidth
            label="Note personnel"
            variant="filled"
            margin="normal"
            name="personnal_note"
            onChange={handleChange}
            defaultValue={formValues.personnal_note}
            multiline
            rows={4}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              mt: 2,
              mb: 1,
            }}
          >
            <DatePicker
              label="Date de début"
              defaultValue={
                formValues.started_date ? dayjs(formValues.started_date) : null
              }
              onChange={(newValue) =>
                newValue !== null
                  ? (formValues.started_date =
                      dayjs(newValue).format('YYYY-MM-DD'))
                  : delete formValues.started_date
              }
              name="started_date"
              slotProps={{
                textField: {
                  variant: 'filled',
                  fullWidth: true,
                  sx: { mr: { xs: 0, sm: 2 } },
                },
                field: { clearable: true },
              }}
            />
            <DatePicker
              label="Date de fin"
              defaultValue={
                formValues.ended_date ? dayjs(formValues.ended_date) : null
              }
              name="ended_date"
              onChange={(newValue) =>
                newValue !== null
                  ? (formValues.ended_date =
                      dayjs(newValue).format('YYYY-MM-DD'))
                  : delete formValues.ended_date
              }
              slotProps={{
                textField: {
                  variant: 'filled',
                  fullWidth: true,
                  sx: { ml: { xs: 0, sm: 2 }, mt: { xs: 3, sm: 0 } },
                },
                field: { clearable: true },
              }}
            />
          </Box>

          <TextField
            fullWidth
            label="Image URL"
            variant="filled"
            margin="normal"
            name="image_url"
            onChange={handleChange}
            defaultValue={formValues.image_url}
          />
          <TextField
            fullWidth
            label="ISBN 10"
            variant="filled"
            margin="normal"
            name="isbn_10"
            onChange={handleChange}
            defaultValue={formValues.isbn_10}
          />
          <TextField
            fullWidth
            label="ISBN 13"
            variant="filled"
            margin="normal"
            name="isbn_13"
            onChange={handleChange}
            defaultValue={formValues.isbn_13}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              onClick={() => navigate('/')}
              fullWidth
              variant="contained"
              sx={{ mt: 2, mr: 1 }}
              color="error"
            >
              Annuler
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2, ml: 1 }}
              fullWidth
            >
              Modifier
            </Button>
          </Box>
        </Box>
      </Container>
    </Base>
  )
}

export default ModificationForm
